@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Rajdhani", sans-serif !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #7228e2;
  --primary_2: #1b0f23;
  --bg: #6b54b6;
}

.tour_create_form{
  width: 100%;
  padding-bottom: 50px;
}
.tour_create_form h2{
  text-align: center;
}
.tour_create_form .inputs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
align-items: center;
  justify-content: space-between;

}
.tour_create_form .inputs input[type="text"], .tour_create_form .inputs textarea, .tour_create_form .inputs label, .tour_create_form .inputs .files{
  background: var(--primary_2);
  color: #fff !important;
  border: none;
  outline: none;
  width: 40%;
  border-bottom: 3px solid var(--primary);
  margin: 10px 0;
  padding: 3px 5px;

}
.tour_create_form .inputs .files{
cursor: pointer;
}
.tour_create_form .inputs textarea{
  width: 100%;
}
.tour_create_form input[type="submit"]{
  width: 100%;
  color: #fff;
  font-weight: bold;
  outline: none;
  border: none;
  padding: 3px 0;
  background: var(--primary_2);
  border-bottom: 2px solid var(--primary);
}
.tour_create_form input[type="submit"]:hover{
  color: var(--primary);
}
.upcomming_tournaments {
  width: 100%;
  padding: 75px 0;
}
.upcomming_tournaments h1 {
  z-index: 3 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
  margin: 0;
}
.tour_blocks{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.tour_blocks .tour_block {
  width: 220px;
  /* height: 450px; */
  background: #1b0f23;
  padding: 10px;
  padding-bottom: 20px;
  margin: 10px;
  border-bottom: 3px solid var(--bg);
  transition: all .5s ease-in-out;
}
.delete{
  color: red !important;

}
/* .tour_blocks .tour_block:hover{
    transform: translate(-5px, -8px);
    box-shadow: 3px 3px 5px var(--bg);
    border: 2px solid var(--bg);
    border-radius: 5px;
} */
.tour_blocks .tour_block img {
  width: 100%;
  height: 200px;
}
.tour_blocks .tour_block .img {
  position: relative;
  height: 70%;
  width: 100%;
  margin: auto;
}
.tour_blocks .tour_block .img .update_img{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary_2);
  display: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.tour_blocks .tour_block .img:hover .update_img{
  display: block;
}
.tour_blocks .tour_block .tour_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tour_blocks .tour_block .tour_content input, .tour_blocks .tour_block .tour_content label {
    font-size: 25px;
    background: none;
    outline: none;
    border: none;
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: bold;
}
.tour_blocks .tour_block .tour_content textarea{
  background: none;
  outline: none;
  color: #fff;
  width: 100%;
}
.button_2{
    background: none;
    outline: none;
    font-size: 17px;
    width: 90%;
    color: #fff;
    padding: 5px 0;
    margin: 10px 0;
    border: 2px solid var(--primary);
    font-weight: bold;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 10;
}
.button_2::before {
    content: "";
    position: absolute;
    left: 0%;
    top: -108px;
    height: 0%;
    width: 120%;
    color: var(--bg);
    background: var(--bg);
    transition: all 0.8s ease;
    transform: rotate(45deg);
    z-index: -1;
  }
  .button_2:hover::before {
    height: 1080%;
  }


  .add_player .inputs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  align-items: center;
    justify-content: space-between;
  }
  .add_player input{
    background: var(--primary_2);
  color: #fff !important;
  border: none;
  outline: none;
  width: 40%;
  border-bottom: 3px solid var(--primary);
  margin: 10px 0;
  padding: 3px 5px;
  }
  .add_player input[type="submit"]{
    width: 100%;
    color: #fff;
    font-weight: bold;
    outline: none;
    border: none;
    padding: 3px 0;
    background: var(--primary_2);
    border-bottom: 2px solid var(--primary);
    transition: all 0.3s ease-in-out;
  }

.add_player input[type="submit"]:hover{
  color: var(--primary) !important;
}

.pre_players_wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.pre_players{
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary_2);
  margin: 10px 0;
  padding: 0 10px; 
}
.pre_players form input{
  border: none;
  outline: none;
  border-bottom: 2px solid var(--primary);
  background: var(--primary_2);
  color: #fff;
  margin: 10px 0;
  width: 100%;
}
.pre_players form, .pre_players button{
  width: 100% !important;
  
}
.table, .table tr{
  color: #fff !important;
}
#add_leader{
  color: #fff;
  padding: 100px 0;
}
.add_leader_form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  color: #fff;
}
.add_leader_form div{
  margin: 10px 0;
}
.add_leader_form p{
  margin-bottom:  0;
  font-weight: bold;
}
.add_leader_form input, .add_leader_form select, #l_game_name{
  background: #111111;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px;
  border-bottom: 2px solid var(--primary);
}
.pointer{
  cursor: pointer !important;
}
.table .button_2{
  margin: 0 !important;
}