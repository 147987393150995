/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@300;400;500;700&display=swap"); */

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--sideBar-theme-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #7228e2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

:root {
  --sideBar-theme-color: #1b0f23;
}

.container {
  box-sizing: border-box;
  font-family: "Rajdhani", sans-serif !important;
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
  /* grid-gap: 0.2rem; */
}

.navbar {
  background: var(--sideBar-theme-color);
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray;
}
.navbar__right span {
  font-weight: blod;
  font-size: 20px;
  color: #fff;
  font-family: "Rajdhani", sans-serif !important;
  text-transform: capitalize;
}
.navbar__right img {
  width: 50px;
}
.nav_icon {
  display: none;
}

.nav_icon > i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar__right {
  display: flex;
  margin-left: auto;
  justify-self: flex-end;
  align-items: center;
}

.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right > a > i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  padding: 7px;
}

.main {

  grid-area: main;
  overflow-y: auto;
  padding: 0 5px;
  background: linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.8)
      ),
      url(../../assests/main-home-rev-2-1024x576.png);
      background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.main__container {
  /* padding: 20px 35px; */
  padding: 0;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.main__greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.main__greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin: 20px 0;
}

.sidebar {
  height: 100%;
  font-family: "Rajdhani", sans-serif !important;
  text-transform: capitalize;
  background: var(--sideBar-theme-color);
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}
.sidebar__title h1 {
  margin: 0 15px;
}
.sidebar__img {
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
  flex-direction: column;
}
.sidebar__img h1 {
  margin-top: 10px;
}
.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #7228e2;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-family: "Rajdhani", sans-serif !important;
  font-weight: 700;
}

.sidebar__link {
  display: block;
  font-family: "Rajdhani", sans-serif !important;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
  font-size: 0.8rem;
}
.sidebar__link:hover {
  text-decoration: none;
  color: #f3f4f6;
  background-color: rgba(255, 255, 255, 0.1);
}
.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 50 !important;
  left: 0 !important;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 0.2fr 2.2fr; */
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }

  .sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }

  .nav_icon {
    display: inline;
  }
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 585px) {
  .navbar__right span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
  .navbar__right span {
    display: none;
  }
  .table_2{
    width: 340px !important;
  }
}
